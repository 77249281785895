import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';

import Sidebar from './components/Sidebar';
import './App.css';

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Home from "./components/home.component";
import Restructuring from "./components/restructuring.component";
import SelCrit from './components/selCrit.compoment';
import Rules from './components/rules.compoment';
import Settings from './components/settings.compoment';
import PassResetRequest from './components/passResetRequest.component';
import PassChange from './components/passChange.component';
import Documentation from './components/documentation.component';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  palette: {
    // mode: 'dark',
    // primary: {
    //   // main: '#ff8217', //orange
    // main: '#0e3a5e', //dark blue
    // },
  },
  typography: {
    fontFamily: ['Archivo', 'Neuzeit', '"Open Sans"', 'Arial', 'sans-serif'].join(','),
    // fontSize: 13,
  }
});

class App extends Component {

  constructor(props) {
    super(props);

  }
  
  //this is called after render
  componentDidMount() {

    // const user = AuthService.getCurrentUser();
    const user = undefined;

    if (user) {
      this.setState({
        currentUser: user,

      });
    }
  }

  logOut() {
    AuthService.logout();
  }

  render() {

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className='container'>
          <Sidebar />
          <div className='content'>
            <main>
              <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path="/home" element={<Home />} /> */}
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/restructuring" element={<Restructuring />} />
                <Route path="/selCrit" element={<SelCrit />} />
                <Route path="/rules" element={<Rules />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/passResetRequest" element={<PassResetRequest />} />
                <Route path="/passChange/:resetId" element={<PassChange />} />
                <Route path="/documentation" element={<Documentation />} />
              </Routes>

            </main>
          </div>

        </div >
      </ThemeProvider>
    );
  }
}

export default App;
