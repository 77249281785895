import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL+'security/';

axios.interceptors.request.use(function (config) {

  document.body.classList.add('loading-indicator');
  return config
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {

  document.body.classList.remove('loading-indicator');
  return response;
}, (error) => {

  document.body.classList.remove('loading-indicator');
  throw error;
});

class AuthService {
  login(username, password) {
    let response = "";
    return axios
      .post(API_URL + "login", {
        "login": username,
        "password": password
      }, { withCredentials: true })
      .then(response => {
        return response;
      })
      ;

  }

  getPermissionsAndSettingsRequest(token) {
    return axios.get(API_URL + "getPermissionsAndSettings", { headers: { Authentication: 'Bearer:' + token }, withCredentials: true })
      .then(response => {
        return response;
      });
  }

  passwordUpdate(resetId, pass) {
    return axios.post(API_URL + 'passwordUpdate', {
      "passwordResetId": resetId,
      "password": pass
    }, {});
  }

  passwordResetRequest(user) {
    return axios.post(API_URL + 'passwordresetrequest/' + user, {}, {});
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("filterProjects");
    localStorage.removeItem("filterBlocks");
    localStorage.removeItem("filterTables");
    localStorage.removeItem("filterStatuses");
    localStorage.removeItem("lastFilterRestructuring");
    localStorage.removeItem("lastFilterSelCrit");
    localStorage.removeItem("lastFilterRules");
  }

  //   register(username, email, password) {
  //     return axios.post(API_URL + "signup", {
  //       username,
  //       email,
  //       password
  //     });
  //   }

  getCurrentUser() {
    let tmpUser = JSON.parse(localStorage.getItem('user'));
    if (tmpUser == null) {
      this.logout();
      window.location.href = '/login';
      return [];
    }
    return JSON.parse(localStorage.getItem('user'));
  }

  getPermissions() {
    return JSON.parse(localStorage.getItem('user')).permissions;
  }

  getAllAppSettings() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user == null || user.settings == null) {
      return [];
    }
    return JSON.parse(localStorage.getItem('user')).settings;
  }

  getAppSetting(settingName) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user == null || user.settings == null) {
      return [];
    }
  
    let settings = user.settings;
    let res = settings.find(obj => obj.name === settingName);
    return res;
  }

  hasPermission(perm) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user == null || user.permissions == null) {
      return false;
    }
    let permissions = user.permissions;
    return permissions.includes(perm);
  }

  isLoggedIn() {
    let tmpUser = JSON.parse(localStorage.getItem('user'));
    if (tmpUser == null) {
      return false;
    }
    return true;
  }
}

export default new AuthService();