import React from "react";

import { TextField, Button, Link, Box, Typography, FormControl } from '@mui/material';

import AuthService from "../services/auth.service";

import { useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

function Login() {
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("");


  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Username is required'),
    password: Yup.string()
      .required('Password is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = data => {
    setMessage("");

    AuthService.login(data.username, data.password).then(
      response => {
        if (response.data.token) {

          //if token is received, then get permissions and save that in localStorage
          AuthService.getPermissionsAndSettingsRequest(response.data.token).then(res => {
            if (res.data) {
              response.data.permissions = res.data.permissions?? []
              response.data.settings = res.data.settings?? []
              response.data.username = data.username;
              localStorage.setItem("user", JSON.stringify(response.data));
              navigate('/home');
              window.location.reload();

            } else {
              response = "login error";
            }

          })
        } else {
          response = "error getting token";
        }

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
      }
    );
  };


  return (
    <div>

      <h3>Login</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl sx={{ m: 1, width: 200 }} >
          <TextField
            required
            id="username"
            size="small"
            label="Username"
            variant="outlined"
            name="username"
            autoComplete="password"
            {...register('username')}
            error={errors.username ? true : false}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.username?.message}
          </Typography>
        </FormControl>

        <FormControl sx={{ m: 1, width: 200 }} >
          <TextField
            required
            id="password"
            size="small"
            label="Password"
            variant="outlined"
            name="password"
            type="password"
            autoComplete="password"
            {...register('password')}
            error={errors.password ? true : false}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.password?.message}
          </Typography>
        </FormControl>


        <FormControl sx={{ m: 1, width: 200 }}>
          <Button
            sx={{ m: 0 }}
            variant="outlined"
            type="submit"
            // onClick={handleLogin}
            onClick={handleSubmit(onSubmit)}
          >Login</Button>

        </FormControl>
        <Box>
          <Link href="/passResetRequest" underline="hover">
            {'Having issues? Reset your password'}
          </Link>
        </Box>

        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}

      </form>

    </div>
  );

}

export default Login;