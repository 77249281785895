import React, { useState } from "react";

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

import * as constants from '../common/constants'
import Chart from 'react-apexcharts'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

function getStatusName(statusId) {
    let statusName = statusId;
    constants.STATUSES.forEach((el) => {
        if (el.id == statusId) {
            statusName = el.val;
        }
    });
    return statusName;
}

function getStatusId(statusName) {
    let statusId = statusName;
    constants.STATUSES.forEach((el) => {
        if (el.val == statusName) {
            statusId = el.id;
        }
    });
    return statusId;
}

function getColor(statusId) {
    switch (statusId) {
        case "D": return "#C0C0C0";
        case "T": return "#f1f1ba";
        case "M": return "#FFE5CC";
        case "I": return "#90ee90";
        case "A": return "#42cc42";
        default: return "#C0C0C0";
    }
}

function Home() {
    const [username, setUsername] = useState("");
    const [charts, setCharts] = useState([]);
    const [chartsSelCrit, setChartsSelCrit] = useState([]);

    const commonStyles = {
        bgcolor: 'background.paper',
        m: 1,
        p: 1,
        border: 1,
        borderRadius: '10px',
        borderColor: '#ddd'
    };


    React.useEffect(() => {
        let user = AuthService.getCurrentUser();
        setUsername(user.username);

        UserService.getRestructuringSummary().then(
            response => {
                let tmpCharts = [];
                if (response && response.data && Object.keys(response.data).length > 0 && Object.keys(response.data.projects).length > 0) {
                    response.data.projects.forEach((el) => {
                        var labels = [];
                        var series = [];
                        var colors = [];

                        el.statuses.forEach((el) => {
                            labels.push(getStatusName(el.statusName));
                            series.push(el.statusValue);
                            colors.push(getColor(el.statusName))
                        });

                        let pieOptions = {
                            chart: {
                                width: 380,
                                type: 'pie',
                                events: {
                                    dataPointSelection: (event, chartContext, config) => {
                                        window.location.href = '/restructuring?table=[]&block=[]&layout=[]&project=["' + el.name + '"]&status=["' + getStatusId(config.w.config.labels[config.dataPointIndex]) + '"]';
                                    }
                                },
                                animations: {
                                    enabled: false,
                                    // enabled: true,
                                    // speed: 800
                                }
                            },
                            plotOptions: {
                                pie: {
                                    expandOnClick: false
                                }
                            },
                            labels: labels,
                            title: {
                                text: el.name,
                                align: 'center'
                            },
                            colors: colors,
                            dataLabels: {
                                style: {
                                    colors: ['#000'],
                                    textOutline: 'none'
                                }
                            },
                            tooltip: {
                                enabled: true,
                                theme: false,
                                style: {
                                    fontSize: '14px',
                                    fontFamily: undefined
                                }
                            },
                            states: {
                                hover: {
                                    filter: {
                                        type: 'darken',
                                        value: 0.80,
                                    }
                                }
                            }
                        }
                        tmpCharts.push(
                            <Box key={el.name} sx={{ ...commonStyles }} >
                                <Grid item xs={4}>
                                    <Chart options={pieOptions} series={series} type="pie" width={380} />
                                </Grid>
                            </Box>
                        )

                    })
                    setCharts(tmpCharts);
                }
            },
            error => {
                setCharts([]);
                // const resMessage =
                //     (error.response.data.errorMessage) ||
                //     error.message ||
                //     error.toString();
                // enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );

        UserService.getSelCritSummary().then(
            response => {
                let tmpCharts = [];
                if (response && response.data && Object.keys(response.data).length > 0 && Object.keys(response.data.projects).length > 0) {
                    response.data.projects.forEach((el) => {
                        var labels = [];
                        var series = [];
                        var colors = [];

                        el.statuses.forEach((el) => {
                            labels.push(getStatusName(el.statusName));
                            series.push(el.statusValue);
                            colors.push(getColor(el.statusName))
                        });

                        let pieOptions = {
                            chart: {
                                width: 380,
                                type: 'pie',
                                events: {
                                    dataPointSelection: (event, chartContext, config) => {
                                        window.location.href = '/selCrit?table=[]&block=[]&layout=[]&project=["' + el.name + '"]&status=["' + getStatusId(config.w.config.labels[config.dataPointIndex]) + '"]';
                                    }
                                },
                                animations: {
                                    enabled: false,
                                    // enabled: true,
                                    // speed: 800
                                }
                            },
                            plotOptions: {
                                pie: {
                                    expandOnClick: false
                                }
                            },
                            labels: labels,
                            title: {
                                text: el.name,
                                align: 'center'
                            },
                            colors: colors,
                            dataLabels: {
                                style: {
                                    colors: ['#000'],
                                    textOutline: 'none'
                                }
                            },
                            tooltip: {
                                enabled: true,
                                theme: false,
                                style: {
                                    fontSize: '14px',
                                    fontFamily: undefined
                                }
                            },
                            states: {
                                hover: {
                                    filter: {
                                        type: 'darken',
                                        value: 0.80,
                                    }
                                }
                            }
                        }
                        tmpCharts.push(
                            <Box key={el.name} sx={{ ...commonStyles }} >
                                <Grid item xs={4}>
                                    <Chart options={pieOptions} series={series} type="pie" width={380} />
                                </Grid>
                            </Box>
                        )

                    })
                    setChartsSelCrit(tmpCharts);
                }
            },
            error => {
                setChartsSelCrit([]);
                // const resMessage =
                //     (error.response.data.errorMessage) ||
                //     error.message ||
                //     error.toString();
                // enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );

    }, []);




    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <header className="jumbotron">
                        <h3>Logged in user: {username}</h3>
                    </header>
                    Restructuring status for each project
                </Grid>
                {charts}

            </Grid>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    Selection criteria status for each project
                </Grid>
                {chartsSelCrit}

                {/* <Grid item xs={4}>
                    <Chart options={pieOptions} series={series} type="pie" width={380} />
                </Grid>
                <Grid item xs={4}>
                    <Chart options={pieOptions} series={series} type="pie" width={380} />
                </Grid>
                <Grid item xs={12}>
                    <Item>xs=12</Item>
                </Grid> */}
            </Grid>
        </Box>

    )
}


export default Home;